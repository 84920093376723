import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import Link from 'next/link';

function Custom404() {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Typography variant="h3" component="h1" displayBlock textAlign="center">
          Error 404
        </Typography>
        <Typography
          variant="subtitle2"
          component="p"
          displayBlock
          textAlign="center"
        >
          Seite nicht gefunden
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Link href="/?from=404" passHref>
            <Button variant="primary" size="md" outlined rounded component="a">
              Home
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Custom404;
